<style scoped>
.lable-title{
    width: 80px;
    display: inline-block;
}
</style>

<template>
    <div>
        <Tabs>
        <TabPane label="任务详情">

         <div class="text-center workplatform-title">
            罚单
        </div>
        <div class="workplatform-title">基础信息</div>
         <Row class="p-b-5" :gutter="8">
                <i-col span="8" style="word-break: break-all;">
                    <label class="title">名称 </label>
                    {{besicInfo.title}}
                </i-col>
                <i-col span="8">
                    <label class="title">罚单类型 </label>
                    {{besicInfo.feeType === 52?'供应商罚单':'代理商罚单'}}
                </i-col>
                <i-col span="8">
                    <label class="title">状态 </label>
                    {{besicInfo.statusName}}
                </i-col>
                <i-col span="8">
                    <label class="title">发起人 </label>
                    {{besicInfo.createUserName}}
                </i-col>
                <i-col span="8">
                    <label class="title">提交时间 </label>
                    {{besicInfo.createTime}}
                </i-col>
                <i-col span="8">
                    <label class="title">代理商 </label>
                    {{besicInfo.agentCompanyName}}
                </i-col>
                <i-col span="8">
                    <label class="title">供应商 </label>
                    {{besicInfo.supplierCompanyName}}
                </i-col>
                <i-col span="8">
                    <label class="title">罚款金额 </label>
                    <span class="text-orange ">{{formatMoney(besicInfo.amount)}}</span>
                </i-col>
                <i-col span="8">
                    <label class="title">罚款日期 </label>
                    {{besicInfo.penaltyDate}}
                </i-col>
                 <i-col span="24">
                    <label class="title">描述 </label>
                    {{besicInfo.content}}
                </i-col>
            </Row>

        <div class="workplatform-title">上传文件清单</div>
        <i-table stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </TabPane>
        <TabPane label="任务进度" >
          <history :type="14" :relate-id="penaltyId" />
        </TabPane>
      </Tabs>

    </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { getPenalty } from '@/api/statement/penalty'
import { toMoney } from '@/utils/wnumb_own'
import History from '@/components/approval/History'

export default {
  components: {
    History
  },
  data () {
    return {
      besicInfo: {},
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              '下载'
            )
          }
        }
      ],

      penaltyId: this.$store.state.approvalCreate.detailId
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { penaltyId: this.penaltyId }
      getPenalty(query).then(res => {
        this.besicInfo = res
        this.approvalFile = res.ossfileList
      })
    },
    getStatusColor (result) {
      let color = 'Gray'
      if (result === 4) { // 待审批
        color = 'Orange'
      } else if (result === 5) { // 已通过
        color = 'Black'
      } else if (result === 6) { // 已拒绝
        color = 'Red'
      }

      return color
    },
    formatMoney (number) {
      return toMoney(number)
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/penalty/downloadpenaltyfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
